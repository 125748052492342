var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"bj"},[_c('div',{staticClass:"table"},[_vm._m(0),_c('div',{staticClass:"list threeBox"},[_c('div',{staticClass:"threeBox_ font_tips",staticStyle:{"width":"33%"},on:{"click":_vm.lx}},[_vm._v(" 会员账号"),_c('br'),_vm._v(" 解锁茶贴"),_c('br'),_vm._v(" 帮忙预约"),_c('br'),_vm._v(" 立即联系"),_c('br')]),_c('div',{staticClass:"threeBox_",staticStyle:{"width":"33%"},on:{"click":_vm.gg}},[_vm._v(" 谷歌浏览器"),_c('br'),_vm._v(" 点击下载"),_c('br'),_vm._v(" 提取密码"),_c('br'),_vm._v(" e5bd ")]),_c('div',{staticClass:"threeBox_",staticStyle:{"width":"33%"},on:{"click":_vm.jsq}},[_vm._v(" 加速器"),_c('br'),_vm._v(" IPLCQ"),_c('br'),_vm._v(" 内置教程"),_c('br'),_vm._v(" 免费体验"),_c('br')])]),_c('div',{staticClass:"list",on:{"click":_vm.fl}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"list",on:{"click":_vm.ypl}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"list",on:{"click":_vm.cg}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"list",on:{"click":_vm.qm}},[_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"list",on:{"click":_vm.lgw}},[_vm._m(9),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list list_ tips"},[_c('span',{staticStyle:{"color":"#CC3333"}},[_vm._v("温馨提示:")]),_vm._v("部分网站服务器位于国外,所以存在无法访问的情况,可以使用下面这款免费加速器进行代理访问, 成功率提高100%,推荐使用谷歌浏览器访问！"),_c('br'),_c('span',{staticStyle:{"color":"#CC3333","font-size":"14px"}},[_vm._v("以下App会员账号,单独解锁帖子,请联系我们!")]),_c('br'),_vm._v(" QQ：468433164 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/two.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("51风流"),_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("( 51ym.xyz )")])]),_c('div',{staticClass:"font"},[_vm._v("85451人推荐")]),_c('div',{staticClass:"font_1"},[_vm._v(" 当地楼凤精准打击，实力验证楼凤贴，真实无套路，品质无忧... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/five.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("一品楼"),_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("( th2024.cc )")])]),_c('div',{staticClass:"font"},[_vm._v("75616人推荐")]),_c('div',{staticClass:"font_1"},[_vm._v(" 欢迎来到一品楼性息交流论坛,建议使用Choreme非国产浏览器进行访问... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/one.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("51茶馆")]),_c('div',{staticClass:"font"},[_vm._v("51429人推荐")]),_c('div',{staticClass:"font_1"},[_vm._v(" 找外围楼凤就上51茶馆，学生、护士一应俱全，中介靠谱安排，方便安全... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/three.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("千妹儿")]),_c('div',{staticClass:"font"},[_vm._v("32872人推荐")]),_c('div',{staticClass:"font_1"},[_vm._v(" 同城约炮，定位附近，学生、护士一应俱全，无中介套路，方便安全... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/four.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("老哥稳")]),_c('div',{staticClass:"font"},[_vm._v("56891人推荐")]),_c('div',{staticClass:"font_1"},[_vm._v(" 老哥稳小姐威客网,最全的小姐姐信息,夜场娱乐信息大全... ")])])
}]

export { render, staticRenderFns }